import * as React from 'react'

import { Button } from '@mui/material'

import {
  MobileDrawer as BaseMobileDrawer,
  DrawerList,
  DrawerListItem,
} from 'shared/components'

import NavBar from '../components/nav_bar'

const drawerWidth = 240

type LandingMobileDrawerProps = {
  open: boolean
  onClose: () => void
}

const MobileDrawer = ({
  open,
  onClose,
}: LandingMobileDrawerProps) => (
  <BaseMobileDrawer
    width={drawerWidth}
    open={open}
    onClose={onClose}
  >
    <DrawerList>
      <DrawerListItem
        text='Iniciar Sesión'
        href='/auth/login/'
      />
    </DrawerList>
  </BaseMobileDrawer>
)

type LayoutProps = {
  showProgress?: boolean
  progressValue?: number
  children: React.ReactNode
}

export const Layout = ({
  showProgress,
  progressValue,
  children,
}: LayoutProps) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false)
  const toggleMobileDrawerOpen = () => {
    setMobileDrawerOpen(!mobileDrawerOpen)
  }
  const onMobileDrawerClose = () => {
    setMobileDrawerOpen(false)
  }

  return (
    <React.Fragment>
      <NavBar
        maxWidth='lg'
        onMenuButtonClick={toggleMobileDrawerOpen}
        showProgress={showProgress}
        progressValue={progressValue}
      >
        <Button
          href='/auth/login/'
          variant='outlined'
          size='large'
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          Iniciar sesión
        </Button>
        <Button
          href='/auth/register'
          variant='contained'
          size='large'
        >
          Empezar
        </Button>
      </NavBar>
      <MobileDrawer
        open={mobileDrawerOpen}
        onClose={onMobileDrawerClose}
      />
      {children}
    </React.Fragment>
  )
}

export default Layout
